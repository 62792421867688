import React from "react"
import "./Navbar.css"
import mob from "../../resources/savana/mobsav.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const Links = () => {
  return (
    <div>
      <a href="/" id="subMenu1">
        Home
      </a>
      <a href="https://www.hirexl.in/" id="subMenu2" target="_blank">
        Job Seeker
      </a>
      <a href="https://recruiters.hirexl.in/" id="subMenu2" target="_blank">
        Employer
      </a>
      <a href="/blog" id="subMenu3">
        Blog
      </a>
      <a href="/contact" id="subMenu4">
        Looking for a Job?
      </a>
      <a href="/contact" id="subMenu5">
        Contact
      </a>
    </div>
  )
}

export default class NavBar extends React.Component {
  render() {
    return (
      <nav id="homePage">
        <div className="navWide">
          <div className="wideDiv">
            <Links />
          </div>
        </div>

        <div className="navNarrow">
          <img src={mob} className="mobSav" alt="mobsav" />
          <FontAwesomeIcon
            icon={faBars}
            color="#6D6D6D"
            size="2x"
            className="bars"
            onClick={this.toggle}
          />
          <FontAwesomeIcon
            icon={faTimes}
            size="2x"
            color="#6D6D6D"
            className="cross"
            onClick={this.toggle}
          />
          <div className="narrowLinks hidden">
            <Links />
          </div>
        </div>
      </nav>
    )
  }

  toggle() {
    let narrowLinks = document.querySelector(".narrowLinks")
    narrowLinks.classList.toggle("hidden")
    let bars = document.querySelector(".bars")
    bars.classList.toggle("hidden")
    let cross = document.querySelector(".cross")
    cross.classList.toggle("visible")
  }
}
