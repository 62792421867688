import React from "react"
import { Helmet } from "react-helmet"
import logo from "../../resources/savana/savana.png"

import "./Header.css"

const Header = ({
  title = "A Human Resource Sourcing Company | Savanna HR",
  canonicalUrl = "https://savannahr.com/",
  description = "Best consultancy in Delhi NCR We are the best HR Consultancy in Delhi NCR because we know what our clients actually need. We hire leaders for the leading organizations.",
}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="HR consultancy in Delhi NCR | best consultancy in Delhi NCR-SAVANNA HR"
        />
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:description"
          content="Best consultancy in Delhi NCR We are the best HR Consultancy in Delhi NCR because we know what our clients actually need. We hire leaders for the leading organizations."
        />
        <title>{title}</title>
        <script type="application+ld/json">
          {`{
            "@context":"https://schema.org",
            "@type":"Organization",
            "name":"Savanna HR",
            "url":"https://savannahr.com",
            "logo":"https://savannahr.com/static/sav-774f192a16166e221249a3d07c18c0bb.png",
            "sameAs": [
              "https://www.facebook.com/savannahrservices",
              "https://www.instagram.com/hrsavanna",
              "https:://www.linkedin.com/company/savanna-hr/",
              "https://twitter.com/Savanna_HR"
            ]
           }`}
        </script>
        <script>
          {`var _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            (function() {
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src='https://cdn.matomo.cloud/technocube.matomo.cloud/container_yOn7wgpK.js'; s.parentNode.insertBefore(g,s);
            })();`}
        </script>
      </Helmet>
      <div className="container-fluid app-header">
        <div className="container">
          <div className="row">
            <img
              referrerpolicy="no-referrer-when-downgrade"
              src="https://static.scarf.sh/a.png?x-pxid=aa629fe1-c8ce-4017-b401-f2e0a266492d"
            />
            <div className="col-sm-12 header">
              <a href="/">
                <img src={logo} className="App-logo" alt="logo" />
              </a>
              <a className="Contact" href="/contact">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
